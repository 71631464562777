// Define your custom theme or choose predefined theme
// @use 'ontimize-web-ngx/theming/themes/ontimize-blue.scss' as theme;
// @use 'ontimize-web-ngx/theming/themes/ontimize-black-yellow.scss' as theme;
@use './custom-heme.scss' as theme;
@use 'ontimize-web-ngx/theming/ontimize-style-v8.scss' as ontimize-style;
@use '../../app/login/login.theme.scss' as login;

// Include ontimize styles
@include ontimize-style.ontimize-theme-styles(theme.$theme);

// Propagate theme to screen styles definition.
@include login.login-theme(theme.$theme);


//Include dark styles option
// .o-dark {
//   @include ontimize-style.ontimize-theme-all-component-color(theme.$dark-theme);
// }

o-column.o-column,
o-row.o-row {

  .o-container>.o-container-header,
  .o-container>.o-container-title,
  .o-container>.mat-expansion-panel-header {
    border-bottom: none;
  }
}

.o-column>.o-container>.o-container-title span,
.o-column>.o-container>.mat-expansion-panel-header .o-container-title span,
.o-column-collapsible>.o-container>.o-container-title span,
.o-column-collapsible>.o-container>.mat-expansion-panel-header .o-container-title span,
.o-row>.o-container>.o-container-title span,
.o-row>.o-container>.mat-expansion-panel-header .o-container-title span,
.o-row-collapsible>.o-container>.o-container-title span,
.o-row-collapsible>.o-container>.mat-expansion-panel-header .o-container-title span {
  font-size: 18px;
  font-weight: 700;
}

// o-email-input {
//   .mat-mdc-form-field {
    // min-width: 330px!important;
//   }
// }

o-date-input,
expedweb-id-input,
expedweb-list-picker,
o-list-picker {
  .mat-mdc-form-field {
    max-width: 212px!important;
  }
}

o-table.o-table .mat-mdc-table .mat-mdc-row .mat-mdc-cell {
  color: rgba(0, 0, 0, 0.87);
}

.o-app-sidenav {
  .o-app-sidenav-container {
    &.o-app-sidenav-mode-desktop:not(.opened) {
      .o-app-sidenav-sidenav.mat-drawer-closed {
        transform: translate3d(-191px, 0, 0);
        -moz-transform: translate3d(-191px, 0, 0);
      }
    }
  }
}

.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding-bottom: 0;
        .mat-mdc-form-field-input-control:not(textarea) {
          height: 1em;
        }
      }
    }
  }
}

.o-dialog-class {
  .mat-mdc-selection-list[dense] .mat-mdc-list-item {
    .mdc-list-item__start {
      align-self: center;
    }

    .mdc-list-item__content {
      .mdc-list-item__primary-text {
        margin-bottom: 0px;
        padding: 0 8px;
      }

      .mdc-list-item__primary-text,
      .mdc-list-item__secondary-text {

        &::before,
        &::after {
          height: auto;
          vertical-align: 0;
        }

        .mat-mdc-list-item-title {
          &::before,
          &::after {
            height: auto;
          }

          ::after {
            vertical-align: 0;
          }
        }
      }
    }
  }
}

.o-table-menu.medium span.mat-mdc-menu-item:first-child {
  display: none;
}


.o-table-visible-columns-dialog .mat-mdc-dialog-content.mat-mdc-dialog-content .mat-mdc-list .mat-mdc-list-item .mdc-list-item__content .mat-icon:last-child {
  cursor: pointer;
}