@use '@angular/material'as mat;
@use '/node_modules/ontimize-web-ngx/theming/ontimize-style.scss'as ontimize-style;
@import './mat-palette-gen.scss';


/* Color definitions */
$primary-color: #242424;
$accent-color: #e53d4e;

// $mat-custom-primary:  (50 : #e5e5e5, 100 : #bdbdbd, 200 : #929292, 300 : #666666, 400 : #454545, 500 : #242424, 600 : #202020, 700 : #1b1b1b, 800 : #161616, 900 : #0d0d0d, A100 : #e5e5e5, A200 : #bdbdbd, A400 : #454545, A700 : #1b1b1b, contrast: (50: #000000DE, 100: #000000DE, 200: #000000DE, 300: white, 400: white, 500: #FFFFFFDE, 600: #FFFFFFDE, 700: #FFFFFFDE, 800: #FFFFFFDE, 900: #FFFFFFDE, A100: #000000DE, A200: white, A400: white, A700: white, ));
// $mat-custom-accent: (50 : #e53d4e, 100 : #e53d4e, 200 : #e53d4e, 300 : #e53d4e, 400 : #e53d4e, 500 : #e53d4e, 600 : #e53d4e, 700 : #e53d4e, 800 : #e53d4e, 900 : #e53d4e, A100 : #e53d4e, A200 : #e53d4e, A400 : #e53d4e, A700 : #e53d4e, contrast: (50: #000000DE, 100: #000000DE, 200: #000000DE, 300: white, 400: white, 500: #FFFFFFDE, 600: #FFFFFFDE, 700: #FFFFFFDE, 800: #FFFFFFDE, 900: #FFFFFFDE, A100: #000000DE, A200: white, A400: white, A700: white, ));
$mat-custom-primary: createPalette($primary-color);
$mat-custom-accent: createPalette($accent-color);

// Define a theme.
$primary: mat.define-palette($mat-custom-primary);
$accent: mat.define-palette($mat-custom-accent);
$theme: ontimize-style.o-mat-light-theme($primary, $accent);
$dark-theme: ontimize-style.o-mat-dark-theme($primary, $accent);
