@import "@angular/material/theming";
@import '/src/assets/css/custom-heme.scss';

/* You can add global styles to this file, and also import other style files */
.o-form-layout-manager {
  .o-form {
    form {
      height: 100%;
    }
  }
}

/* Form toolbar background color */
.o-form-toolbar .o-form-toolbar-header.mat-toolbar {
  background-color: transparent;
}


.o-column>div.o-container .o-container-scroll.o-container-gap,
.o-row>div.o-container .o-container-scroll.o-container-gap {
  padding: 15px;
}

.avatar.o-image {
  width: 200px;
  height: 200px;

  .o-image-content.o-image-auto-fit {
    .o-image-display-container {
      img {
        border-radius: 50%;
        aspect-ratio: auto 200/200;
        width: 200px;
        height: 200px;
      }
    }

    .o-image-form-field {
      position: absolute;
      bottom: -42px;
    }
  }
}

.mat-mdc-form-field {
  opacity: 0.8;
}

.editable-special {
  .mat-mdc-form-field {
    .mdc-text-field--filled {
      background-color: #ffffff;
    }
  }
}

.o-table {
  .mat-mdc-table {
    .mat-mdc-row {
      &:hover:not(.o-table-group-row) {
        cursor: pointer;
        background-color: #dfc2c6;
      }
    }
  }
}

.o-table-quickfilter {
  .quickFilter {
    .mat-mdc-form-field {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          align-items: center;
        }
      }
    }
  }
}

// Resize list-picker dialogs
.cdk-overlay-list-picker {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    max-width: none !important;
    max-height: none !important;
    resize: both;
  }
}

// Resize all dialogs
.mat-mdc-dialog-container .mdc-dialog__surface {
  max-width: none !important;
  max-height: none !important;
  resize: both;
}

// Avoid resize dialog service dialogs
.o-dialog-service .mat-mdc-dialog-container .mdc-dialog__surface {
  resize: none;
}

// .custom-button {
// &:not([disabled]) {
// color: #1464a5 !important;
// color: #e53d4e !important;
// }
// }

// .accept-dialog-button {
//   &:not([disabled]) {
//     background-color: #b9d1e4;
//     color: #1464a5 !important;
//   }
// }

.delete-button {
  &:not([disabled]) {
    // background-color: #cc0000;
    color: #ff0000 !important;
  }
}

button:not(.mat-mdc-unelevated-button):not(.o-form-toolbar-button-delete):not(.mat-mdc-raised-button) {
  &:not([disabled]) {
    color: mat-color($primary) !important;

    &:hover {
      color: mat-color($accent) !important;
      /* border-color: #e53d4e;*/
    }
  }
}

button {
    &:hover {
      color: mat-color($accent) !important;
          /* border-color: #e53d4e;*/
    }
}

.o-action-cell-renderer {
  &:hover {
    color: mat-color($accent) !important;
  }
}

// button:not(.o-form-toolbar-button-delete) {
//   color: mat-color($primary) !important;
// }

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 100px;
  height: 100px;
}

// .o-app-sidenav-image .o-app-sidenav-image-container {
// margin: -48px 40px 0 0 !important;
// }

// .o-app-sidenav .o-app-sidenav-container.opened .mat-drawer.o-app-sidenav-sidenav {
//   width: 320px !important;
// }

// .o-app-sidenav .o-app-sidenav-container.opened .mat-drawer-content.o-app-sidenav-content {
//   margin-left: 320px !important;
// }

.cell-editable {
  background-color: #1464a52b !important;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  // para línea inferior de los inputs
  padding-bottom: 0.1em;
  padding-top: 0.1em;
}

.mat-mdc-form-field .mat-mdc-input-element {
  // para la ñ
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

.o-table.o-table-disabled {
  opacity: 0.8 !important;
}